import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {
  pdfSrc = '';
  isLoading = true;
  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.pdfSrc = this.activeRoute.snapshot.queryParams['location'];
  }
  afterLoad() {
    this.isLoading = false;
  }


}

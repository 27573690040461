import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import {from, fromEvent} from "rxjs";

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {
  // search 搜索功能
  searchInfo = '';
  id = '';
  // patientInfo 病人信息
  url = '/service/doctor/';
  patientInfo = [];
  tableScroll: any;
  // sort 排序
  sort = {
    name_order: true,
    create_date_order: true
  };
  // open file 下载文件
  openUrl = 'http://101.200.164.160:4200';
  // page 分页功能
  pageIndex = 1;
  pageCount = 15;
  totalPage = 1;
  // tips 消息提示
  showTips = false;
  tipsMessage = '';

  constructor(private http: HttpClient,
              private router: Router,
              private activeRoute: ActivatedRoute
              ) { }

  ngOnInit() {
    this.id = this.activeRoute.snapshot.queryParams['id'];
    this.getPatient()
  }
  // 获取病人列表
  getPatient() {
    this.http.get(this.url + `?uuid=${this.id}&page_index=${this.pageIndex}&page_per_count=${this.pageCount}`).subscribe(
      (data: any) => {
        if (data.status) {
          console.log(data);
          this.patientInfo = this.patientInfo.concat(data.data.record);
          this.pageIndex = +data.data.page_index + 1;
          this.totalPage = +data.data.page_count;
          if (this.pageIndex != this.totalPage + 1) {
            this.getPatient();
          }
        } else {
          this.errorFun(data.message);
        }
      }
    );
  }
  // 回车搜索功能
  searchEnter(e) {
    const key_code = window.event ? e.keyCode : e.which;
    if (key_code == 13) {
      this.search();
    }
  }
// 搜索功能
  search() {
    this.http.get(this.url + `?uuid=${this.id}&patient_name=${this.searchInfo}&page_index=${this.pageIndex}&page_per_count=${this.pageCount}`).subscribe(
      (data: any) => {
        if (data.status) {
          this.patientInfo = data.data.record;
        } else {
          this.errorFun(data.message);
        }
      }
    )
  }
  // 排序
  sortInfo(type) {
    this.sort[type] = !this.sort[type];
    this.http.get(this.url + `?uuid=${this.id}&patient_name=${this.searchInfo}&${type}=${this.sort[type]}&page_index=${this.pageIndex}&page_per_count=${this.pageCount}`).subscribe(
      (data: any) => {
        if (data.status) {
          this.patientInfo = data.data.record;
        } else {
          this.errorFun(data.message);
        }
      }
    )
  }
  // file 打开文件
  openFile(type, location, uuid) {
    if (!location) {return;}
    if (type === 'pdf') {
      this.router.navigate(['pdf'], {queryParams: {location:  location}});
    } else {
      console.log(uuid)
      this.router.navigate(['image'], {queryParams: {uuid: uuid}});
    }
  }
// tips 消息提示
  errorFun(message) {
    this.showTips = true;
    this.tipsMessage = message;
    window.setTimeout(()=>{
      this.showTips = false;
    }, 2000);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {PatientComponent} from "./patient/patient.component";
import {ImageComponent} from "./image/image.component";
import { PdfComponent} from "./pdf/pdf.component";
import {ReceiptComponent} from "./receipt/receipt.component";

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/image',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'patient',
    component: PatientComponent
  },
  {
    path: 'image',
    component: ImageComponent
  },
  {
    path: 'pdf',
    component: PdfComponent
  },
  {
    path: 'receipt',
    component: ReceiptComponent
  },
  {
    path: '**',
    redirectTo: 'error404'
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {useHash: false}),
  ],
  declarations: [],
  exports: [RouterModule]
})


export class AppRoutingModule {

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpClientModule} from "@angular/common/http";
import { ReceiptComponent } from './receipt/receipt.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PatientComponent } from './patient/patient.component';
import { ImageComponent } from './image/image.component';
import { AppRoutingModule} from "./app-routing.modules";
import { PdfComponent } from './pdf/pdf.component';
import {PdfViewerModule} from "ng2-pdf-viewer";
import { APP_BASE_HREF, HashLocationStrategy,LocationStrategy, PathLocationStrategy} from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PatientComponent,
    ImageComponent,
    PdfComponent,
    ReceiptComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PdfViewerModule
  ],
  providers: [
         {provide:LocationStrategy,
    useClass:HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

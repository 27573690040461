import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // userInfo 用户消息
  url = "/service/doctorlogin/";
  telphone = "";
  code = "";

  // code 验证码消息
  codeUrl = '/service/sms/';
  isSend = false;
  timer = 60;
  sendInfo = "发送验证码";

  // tips 消息提示
  showTips = false;
  tipsMessage = '已发送验证码请注意查收';

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
  }
  // 发送验证码
  sendCode() {
    this.http.get(this.codeUrl + `?phone=${this.telphone}`).subscribe(
      (data: any)=>{
        if (data.status) {
          this.isSend = true;
          let intervalTimer = window.setInterval(()=>{
          this.timer--;
          if (this.timer == 0) {
            this.sendInfo = "重新发送";
            this.isSend = false;
            this.timer = 60;
            window.clearInterval(intervalTimer);
          }
        }, 1000);
          // tips
          this.errorFun("验证码已发送，请稍候...");
        } else {
          this.errorFun(data.message);
        }
        console.log(data);
      }
    )
  }
  // 提交信息
  login() {
    this.http.post(this.url, {'phone': this.telphone, 'code': this.code}).subscribe(
      (data: any)=> {
        if (data.status) {
          this.router.navigate(['patient'], {queryParams: {id: data.data.uuid}})
        } else {
          this.errorFun(data.message);

        }
      }
    )
  }
  // 显示提示消息
  errorFun(message) {
    this.showTips = true;
    this.tipsMessage = message;
    window.setTimeout(()=>{
      this.showTips = false;
    }, 2000);
  }
}

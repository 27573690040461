import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  // 病史
  url = '/register/receipt/';
  treatment_selection = [];
  total = 0;
  treatment_history = '';
  userInfo = {};
  constructor(private http: HttpClient,private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    // 获取病人病史 购买产品相关信息
    var number = this.activeRoute.snapshot.queryParams['number'];
    this.http.get(this.url+ `?uuid=${number}`).subscribe(
      (data: any) => {
        console.log(data);
        if (data.status) {
          this.userInfo = data.data;
         let products = data.data.treatment_selection.split(',');
         let history = data.data.treatment_history.split(',');
         products.forEach(item=>{
           this.treatment_selection.push({name: item.split('-')[0], price: item.split('-')[1]});
           this.total -= -item.split('-')[1];
         });
         history.forEach(item => {
           if (item.indexOf('是') != -1) {
             this.treatment_history += item.split('-')[0] + '  ';
           }
         });
         if (!this.treatment_history) {
           this.treatment_history = '无';
         }
        } else {

        }
      }
    )
  }

}

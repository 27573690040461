import { Component, OnInit, AfterViewInit, OnChanges } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare var wx: any;
import vtkFullScreenRenderWindow from 'vtk.js/Sources/Rendering/Misc/FullScreenRenderWindow';
import vtkURLExtract from 'vtk.js/Sources/Common/Core/URLExtract';
import vtkSTLReader from 'vtk.js/Sources/IO/Geometry/STLReader';
import vtkOBJReader from 'vtk.js/Sources/IO/Misc/OBJReader';
import vtkMTLReader from 'vtk.js/Sources/IO/Misc/MTLReader';
import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';
import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import vtkSphereSource from 'vtk.js/Sources/Filters/Sources/SphereSource';
import vtkXMLPolyDataReader from 'vtk.js/Sources/IO/XML/XMLPolyDataReader';
import vtk from 'vtk.js/Sources/vtk';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})

export class ImageComponent implements OnInit, OnChanges {
  nonceStr: any;
  jsapi_ticket: any;
  timestamp: any;
  signature: any;
  url: any;
  url_type: any;
  // url
  angle_list = [];
  upixel_stl_url = '/api/v1/record/3ddisplay/';
  stlLocation: any;
  stl_uuid_url = '/service/stl/';
  title = 'frontend-display';
  camera: any;
  user_info: any;
  camera_focus: any;
  uuid: any;
      pip_name: any;
  length_list = [];
  clientHeight: any;
  container: any;
  fullScreenRenderer: any;
  renderWindow: any;
  resetCamera: any;
  renderer: any;
    render: any;
  // example_stl=[
    showTips = false;
  tipsMessage = '已发送验证码请注意查收';
  isLoading = false;
  is_demo = 'false';

  // 大写数字
  bigNum = new Map([
    [1, '一'],
    [2, '二'],
    [3, '三'],
    [4, '四'],
    [5, '五'],
    [6, '六'],
    [7, '七'],
    [8, '八'],
    [9, '九'],
    [10, '十']
  ]);
  colorUrl = '/api/v1/record/3dmodule/colors/';
  color_list = [];
  color_rgb_list = [];
  length_one = [];
  length_two = [];
  vessel_color = [];
  aneurysm_color = [];
  constructor( private activeRoute: ActivatedRoute, private http: HttpClient) {

  }

  ngOnInit() {
    this.uuid = this.activeRoute.snapshot.queryParams['uuid'];
    this.url_type = this.activeRoute.snapshot.queryParams['type'];
    this.is_demo = this.activeRoute.snapshot.queryParams['is_demo'];
    const location_check = this.activeRoute.snapshot.queryParams['location'];
    if (location_check !== undefined) {
      this.stlLocation = location_check;
    }
    this.getColor();
  }

  ngOnChanges() {
    // this.stl_location = this.example_stl;
    if (this.stlLocation !== [] && this.stlLocation !== undefined) {
      // this.stl_location = this.example_stl;
      this.load();
    }
    // else{
    //   console.log('reload')
    //   this.stl_location = [];
    //   this.load();
    // }
  }
  getColor() {
    this.http.get(this.colorUrl).subscribe(
      (data: any) => {
        if (data.status) {
          this.color_list.push(data.data[0].color_name, data.data[1].color_name);
          this.color_rgb_list.push(data.data[0].color, data.data[1].color);
          data.data.forEach((item) => {
            if (item.key === 'aneurysm') {
              this.aneurysm_color = item.color;
            }
            if (item.key === 'vessel') {
              this.vessel_color = item.color;
            }
            if (item.key === 'length1') {
              this.length_one = item.color;
            }
            if (item.key === 'length2') {
              this.length_two = item.color;
            }
          });
          console.log(this.stlLocation,999)
          if (this.stlLocation === undefined) {
            this.getSTL();
          }

        }
      }
    );
  }
  getSTL() {
    let get_stl_url = this.stl_uuid_url + `?uuid=${this.uuid}`;
    if (this.url_type == 'upixel') {
        get_stl_url = this.upixel_stl_url + `?uuid=${this.uuid}`;
    }
    this.http.get(get_stl_url).subscribe(
      (data: any) => {
        if (data.status) {
          this.stlLocation = data.data.stl;
          this.user_info = data.data.user;
          if (this.is_demo == 'true') {
            this.user_info.name = 'XXX';
          }
          if (this.user_info) {
            if (this.user_info.sexy === 0) {
              this.user_info.sexy = '女';
            } else {
              this.user_info.sexy = '男';
            }
            console.log('=======分享=======');
            this.onshare(data.data.user);
            this.create_sence();
          }
        } else {
          this.errorFun(data.message);
        }
      }
    );
  }

  errorFun(message) {
    this.showTips = true;
    this.tipsMessage = message;
    window.setTimeout(() => {
      this.showTips = false;
    }, 2000);
  }



  create_sence() {
    this.container = document.querySelector('.modelViewDiv');
  this.fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
    // background: [0, 0, 0, 0],
    rootContainer: this.container,
    containerStyle: { width: '100%', height: '100%', position: 'relative', opacity: '0.8'}
  });
  this.renderer = this.fullScreenRenderer.getRenderer();
  this.renderWindow = this.fullScreenRenderer.getRenderWindow();
  this.resetCamera = this.renderer.resetCamera;
  this.render = this.renderWindow.render;
  console.log(this.stlLocation,999)
   if (this.stlLocation !== [] && this.stlLocation !== undefined) {
     this.drawAngle();
     this.load();
   }
  }


  load() {
    console.log(this.stlLocation,888)
  // const reader = vtkOBJReader.newInstance();
  const vessel_reader = vtkSTLReader.newInstance();
  const aneurysm_reader = vtkSTLReader.newInstance();
  const pipelinePorosity_vtp_reader = vtkXMLPolyDataReader.newInstance();
  const pipelineWire_vtp_reader = vtkXMLPolyDataReader.newInstance();
  const vessel_vtp_reader = vtkXMLPolyDataReader.newInstance();
  // const container = document.querySelector('.modelViewDiv');
  // const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
  //   background:[0,0,0,0],
  //   rootContainer: container,
  //   containerStyle: { width:'100%',height:'100%',position:'relative'}
  // });
  // const renderer = fullScreenRenderer.getRenderer();
  // const renderWindow = fullScreenRenderer.getRenderWindow();
  //
  // const resetCamera = renderer.resetCamera;
  // const render = renderWindow.render;
  const path_objreader = vtkOBJReader.newInstance({ splitMode: 'usemtl' });
  const path_materialsReader = vtkMTLReader.newInstance();
  const moulding_objreader = vtkOBJReader.newInstance({ splitMode: 'usemtl' });
  const moulding_materialsReader = vtkMTLReader.newInstance();
  const scene = [];
  let pipelinePorosity_vtp_location = '';
  let pipelineWire_vtp_location = '';
  let vessel_vtp_reader_location = '';
  let obj_moulding_location = '';
  let mtl_moulding_location = '';
  let mtl_path_location = '';
  let obj_path_location = '';
  let aneurysm_location = '';
  let vessel_location = '';
  let aneurysm_vtp_reader_location = '';

  let pipelinePorosity_show = true;
  if (this.stlLocation != []) {
    // for ( let i = 0; i < this.stlLocation.length; i++ ) {
    //   console.log(this.stlLocation[i])
    // }
    let is_3D = 0;
    this.stlLocation.forEach((location) => {
      if (location['name'] === 'pipelinePorosity' && location['type'] === 'vtp') {
        pipelinePorosity_vtp_location = location['stl_location'];
        pipelinePorosity_show = location['show'];
        if (pipelinePorosity_vtp_location) {
          is_3D++;
        }

      }
      if (location['name'] === 'pipelineWire' && location['type'] === 'vtp') {
        pipelineWire_vtp_location = location['stl_location'];
        if (pipelineWire_vtp_location) {
          is_3D++;
        }

      }
      if (location['name'] === 'vessel_vtp' && location['type'] === 'vtp') {
        vessel_vtp_reader_location = location['stl_location'];
        if (vessel_vtp_reader_location) {
          is_3D++;
        }

      }
      if (location['name'] === 'pipe' && location['type'] === 'obj') {
        obj_moulding_location = location['stl_location'];
        if (obj_moulding_location) {
          is_3D++;
        }

      }
      if (location['name'] === 'pipe' && location['type'] === 'mtl') {
        mtl_moulding_location = location['stl_location'];
      }
      if (location['name'] === 'path' && location['type'] === 'mtl') {
        mtl_path_location = location['stl_location'];
      }
      if (location['name'] === 'path' && location['type'] === 'obj') {
        obj_path_location = location['stl_location'];
        if (obj_path_location) {
          is_3D++;
        }
      }
      if (location['name'] === 'vessel') {
        if (location['type'] === 'stl') {
          vessel_location = location['stl_location'];
          if (vessel_location) {
            is_3D++;
          }
        }
        if (location['type'] === 'vtp') {
          vessel_vtp_reader_location = location['stl_location'];
          if (vessel_vtp_reader_location) {
            is_3D++;
          }
        }
      }
      if (location['name'] === 'aneurysm') {
        if (location['type'] === 'stl') {
          aneurysm_location = location['stl_location'];
          if (aneurysm_location) {
            is_3D++;
          }
        }
        if (location['type'] === 'vtp') {
          aneurysm_vtp_reader_location = location['stl_location'];
          if (aneurysm_vtp_reader_location) {
            is_3D++;
          }
        }
      }
    });
    if (is_3D === 0) {
      this.isLoading = false;
    }
  } else {
    this.isLoading = false;
  }
  if (vessel_location) {
    vessel_reader.setUrl(vessel_location)
    .then(
    () => {
      const  actor = vtkActor.newInstance();
      const  mapper = vtkMapper.newInstance();
      actor.setMapper(mapper);
      mapper.setInputConnection(vessel_reader.getOutputPort());
      actor.getProperty().setOpacity(0.5);
      if (this.vessel_color.length > 0) {
        actor.getProperty().setOpacity(this.vessel_color[3] / 100);
        actor.getProperty().setColor(this.vessel_color[0] / 255, this.vessel_color[1] / 255, this.vessel_color[2] / 255);
      }
      this.renderer.addActor(actor);
       this.resetCamera();
      this.render();
      this.isLoading = false;
    }
  );
  }
  if (aneurysm_location) {
    aneurysm_reader.setUrl(aneurysm_location)
    .then(
    () => {
      const  actor = vtkActor.newInstance();
      const  mapper = vtkMapper.newInstance({ scalarVisibility: true });
      actor.setMapper(mapper);
      mapper.setInputConnection(aneurysm_reader.getOutputPort());
      actor.getProperty().setOpacity(this.aneurysm_color[3] / 100);
      actor.getProperty().setColor(this.aneurysm_color[0] / 255, this.aneurysm_color[1] / 255, this.aneurysm_color[2] / 255);
      this.renderer.addActor(actor);
      this.isLoading = false;
    }
  );
  }
  if (mtl_path_location) {
    path_materialsReader
    .setUrl(mtl_path_location)
    .then(() => {
      path_objreader
        .setUrl(obj_path_location)
        .then(() => {
          this.isLoading = false;
          const size = path_objreader.getNumberOfOutputPorts();
          for (let i = 0; i < size; i++) {
            const polydata = path_objreader.getOutputData(i);
            const name = polydata.get('name').name;
            const  actor = vtkActor.newInstance();
            const  mapper = vtkMapper.newInstance();
            actor.setMapper(mapper);
            mapper.setInputData(polydata);
            path_materialsReader.applyMaterialToActor(name, actor);
            this.renderer.addActor(actor);
            scene.push({name, polydata, mapper, actor});
          }
          this.resetCamera();
          this.render();
          this.isLoading = false;
        });
    });
  }
  if (mtl_moulding_location) {
    moulding_materialsReader
    .setUrl(mtl_moulding_location)
    .then(() => {
      moulding_objreader
        .setUrl(obj_moulding_location)
        .then(() => {
          const size = moulding_objreader.getNumberOfOutputPorts();
          for (let i = 0; i < size; i++) {
            const polydata = moulding_objreader.getOutputData(i);
            const name = polydata.get('name').name;
            const  actor = vtkActor.newInstance();
            const  mapper = vtkMapper.newInstance();
            actor.setMapper(mapper);
            mapper.setInputData(polydata);
            moulding_materialsReader.applyMaterialToActor(name, actor);
            this.renderer.addActor(actor);
            scene.push({name, polydata, mapper, actor});
          }
          this.resetCamera();
          this.render();
          this.isLoading = false;
        });
    });
  }
  if (pipelinePorosity_vtp_location) {
    pipelinePorosity_vtp_reader.setUrl(pipelinePorosity_vtp_location)
    .then(
    () => {
        const polydata1 = pipelinePorosity_vtp_reader.getOutputData();
        const polydata = this.rebuild_polydata(polydata1);
        const  actor = vtkActor.newInstance();
        const  mapper = vtkMapper.newInstance({ interpolateScalarsBeforeMapping: true });
        mapper.setInputData(polydata);
        const lut = mapper.getLookupTable();
         const range_list = polydata1.getPointData().getArrayByName('rangeArray').getRange();
         mapper.setScalarRange(range_list[0], range_list[1]);
         if (!pipelinePorosity_show) {
           actor.getProperty().setOpacity(0);
         } else {
           actor.getProperty().setOpacity(1);
         }
        //   mapper.setScalarModeToUsePointData("Porosity")
        // mapper.setColorByArrayName("Porosity");
        // mapper.setScalarVisibility(true)
        lut.setHueRange(0.667, 0.001);
        actor.setMapper(mapper);
        this.renderer.addActor(actor);
        scene.push({name, polydata, mapper, actor});
        this.resetCamera();
        this.render();
              this.isLoading = false;

    }
  );
  }
  if (pipelineWire_vtp_location) {
    pipelineWire_vtp_reader.setUrl(pipelineWire_vtp_location)
    .then(
    () => {
        const polydata = pipelineWire_vtp_reader.getOutputData();
        const  actor = vtkActor.newInstance();
        const  mapper = vtkMapper.newInstance();
        actor.setMapper(mapper);
        mapper.setInputData(polydata);
        console.log(mapper);
        this.renderer.addActor(actor);
        scene.push({name, polydata, mapper, actor});
        this.resetCamera();
        this.render();
              this.isLoading = false;

    });
  }
  if (aneurysm_vtp_reader_location) {
    vessel_vtp_reader.setUrl(aneurysm_vtp_reader_location)
    .then(
    () => {
        const polydata = vessel_vtp_reader.getOutputData();
        const  actor = vtkActor.newInstance();
        const  mapper = vtkMapper.newInstance();
        actor.setMapper(mapper);
        mapper.setInputData(polydata);
        actor.getProperty().setOpacity(this.aneurysm_color[3] / 100);
        actor.getProperty().setColor(this.aneurysm_color[0] / 255, this.aneurysm_color[1] / 255, this.aneurysm_color[2] / 255);
        this.renderer.addActor(actor);
        scene.push({name, polydata, mapper, actor});
        this.resetCamera();
        this.render();
        this.isLoading = false;
    });
  }
  if (vessel_vtp_reader_location) {
    vessel_vtp_reader.setUrl(vessel_vtp_reader_location)
    .then(
    () => {
        const polydata = vessel_vtp_reader.getOutputData();
        const  actor = vtkActor.newInstance();
        const  mapper = vtkMapper.newInstance();
        actor.setMapper(mapper);
        mapper.setInputData(polydata);
        actor.getProperty().setOpacity(0.5);
        if (this.vessel_color.length > 0) {
          actor.getProperty().setOpacity(this.vessel_color[3] / 100);
        }
        console.log(mapper);
        this.renderer.addActor(actor);
        scene.push({name, polydata, mapper, actor});
        this.resetCamera();
        this.render();
              this.isLoading = false;

    });
  }
       this.drawAngle();

  }

   drawAngle() {
    // const color_list = ['red', 'green', 'blue', 'yellow', 'pink'];
    // const color_rgb_list = [[255, 0, 0], [0, 128, 0], [0, 0, 255], [255, 255, 0], [255, 192, 203]];
    const radius = 0.5;
    const segemnt = 20;
    const rings = 20;
    let color_index = 0;
    for (let index = 0; index < this.stlLocation.length; index++) {
      let check_repeat = true;
      let check_length_repeat  = true;
      if ( this.stlLocation[index]['type'] == 'angle') {
        for (let i = 0; i < this.angle_list.length; i++) {
            if (this.angle_list[i]['name'] === this.stlLocation[index]['name']) {
              check_repeat = false;
            }
        }
        if (check_repeat && this.stlLocation[index]['x']) {
          // console.log('draw')
          this.stlLocation[index]['color'] = 'rgb(' + this.color_rgb_list[color_index][0] + ',' + this.color_rgb_list[color_index][1] + ',' + this.color_rgb_list[color_index][2] + ')';
          this.angle_list.push(this.stlLocation[index]);
          const sphereSource = vtkSphereSource.newInstance();
          sphereSource.setCenter(parseFloat(this.stlLocation[index]['x']),
            parseFloat(this.stlLocation[index]['y']), parseFloat(this.stlLocation[index]['z']));
          const actor = vtkActor.newInstance();
          const mapper = vtkMapper.newInstance();
          actor.setMapper(mapper);
          mapper.setInputConnection(sphereSource.getOutputPort());
          actor.getProperty().setColor(this.color_rgb_list[color_index][0], this.color_rgb_list[color_index][1], this.color_rgb_list[color_index][2]);
          console.log(actor);
          this.renderer.addActor(actor);
          this.resetCamera();
          this.render();
          // var sphereMaterial_1 = new THREE.MeshLambertMaterial({
          // color: color_list[color_index]
          // })
          // var ball = new THREE.Mesh(new THREE.SphereGeometry(radius, segemnt, rings), sphereMaterial_1);
          // ball.position.set(this.stlLocation[index]['x'], this.stlLocation[index]['y'], this.stlLocation[index]['z']);
          // this.scene.add(ball);
          color_index = color_index + 1;
      }
      }if (this.stlLocation[index]['type'] == 'length') {
        for (let i = 0; i < this.length_list.length; i++) {
            if (this.length_list[i]['name'] === this.stlLocation[index]['name']) {
              check_length_repeat = false;
            }
        }if (check_length_repeat) {
         if (this.stlLocation[index]['name'] == 'length1') {
          this.stlLocation[index].color = 'rgb(' +  this.length_one[0] + ',' + this.length_one[1] + ',' +  this.length_one[2] + ')';
          this.stlLocation[index]['chinese_name'] = '第一段长度';
        }
        if (this.stlLocation[index]['name'] == 'length2') {
          this.stlLocation[index]['color'] = 'rgb' + '(' +  this.length_two[0] + ',' + this.length_two[1] + ',' +  this.length_two[2] + ')';
          this.stlLocation[index]['chinese_name'] = '第二段长度';
        }
        if (this.stlLocation[index]['name'] == 'length3') {
          this.stlLocation[index]['color'] = 'rgb(1,62,125)';
          this.stlLocation[index]['chinese_name'] = '第三段长度';
        }
        if (this.stlLocation[index]['name'] == 'length4') {
          this.stlLocation[index]['color'] = 'rgb(223,28,54)';
          this.stlLocation[index]['chinese_name'] = '第四段长度';
        }
         this.length_list.push(this.stlLocation[index]);
        }
      }
      if (this.stlLocation[index]['type'] == 'name') {
        this.pip_name = this.stlLocation[index]['data'];
      }
    }
  }
      rebuild_polydata(data) {
    console.log(data.getPoints().getDataType());
    const polydata = vtk({
          vtkClass: 'vtkPolyData',
          points: {
            vtkClass: 'vtkPoints',
            dataType: data.getPoints().getDataType(),
            numberOfComponents: data.getPoints().getNumberOfComponents(),
            values: data.getPoints().getData(),
          },
          polys: {
            vtkClass: 'vtkCellArray',
            dataType: data.getPolys().getDataType(),
            values: data.getPolys().getData(),
          },
          pointData: {
            vtkClass: 'vtkDataSetAttributes',
            activeScalars: 0,
            arrays: [{
              data: {
                vtkClass: 'vtkDataArray',
                name: 'pointScalars',
                dataType: data.getPointData().getArrayByName('rangeArray').getDataType(),
                values: data.getPointData().getArrayByName('rangeArray').getData(),
              },
            }],
          }
        });
    return polydata;
  }

  onshare(userinfo) {
    console.log('======userinfo======', userinfo);
    const jsapi = `/api/v1/record/token/?url=${encodeURIComponent(window.location.href)}`;
    this.http.get(jsapi).subscribe(
      (data: any) => {
        const signture = data.data.signsture;
        this.nonceStr = signture.nonceStr;
        this.jsapi_ticket = signture.jsapi_ticket;
        this.signature = signture.signature;
        this.timestamp = signture.timestamp;
        this.url = signture.url;
      wx.config({
      appId: 'wx79aefc53609cbf55',
      timestamp: this.timestamp,
      nonceStr: this.nonceStr,
      signature: this.signature,
      jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage']  // 需要使用的JS接口列表
    });
    wx.ready(function () {
      // 需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: '脑卒中智能诊疗平台', // 分享标题
        desc: `${userinfo.name}\r\n${userinfo.age}岁\r\n手术模拟`, // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        // imgUrl: 'http://3d.unionstrongtech.com/statics/share.png', // 分享图标
        imgUrl: 'https://downloads.unionstrongtech.com/ops/static/newlogo.png', // 分享图标
        success: function () {

          // 设置成功
        }
      });
    });
    wx.ready(function () {
// 需在用户可能点击分享按钮前就先调用
      wx.updateTimelineShareData({
        title: '脑卒中智能诊疗平台', // 分享标题
        desc: `${userinfo.name}\r\n${userinfo.age}岁\r\n手术模拟`, // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://downloads.unionstrongtech.com/ops/static/newlogo.png', // 分享图标
        success: function () {

          // 设置成功
        }
      });
    });
    wx.ready(function () {
// 需在用户可能点击分享按钮前就先调用
      wx.onMenuShareTimeline({
        title: '脑卒中智能诊疗平台', // 分享标题
        desc: `${userinfo.name}\r\n${userinfo.age}岁\r\n手术模拟`, // 分享描述
        link: 'http://3d.unionstrongtech.com/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://downloads.unionstrongtech.com/ops/static/newlogo.png', // 分享图标
        success: function () {

          // 设置成功
        }
      });
      }
    );
    wx.ready(function () {
// 需在用户可能点击分享按钮前就先调用
      wx.onMenuShareAppMessage({
        title: '脑卒中智能诊疗平台', // 分享标题
        desc: `${userinfo.name}\r\n${userinfo.age}岁\r\n手术模拟`, // 分享描述
        link: 'http://3d.unionstrongtech.com/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://downloads.unionstrongtech.com/ops/static/newlogo.png', // 分享图标
        success: function () {
          // 设置成功
        }
      });
    }
    );
      }
    );
  }

}
